import { useCallback, useContext, useRef, useEffect } from "react";
import { getUserSubscription, getCookie } from "api";
import AuthContext from "context/AuthContext";

// Global request tracking
let activeRequest = null;
let lastRequestTime = 0;
const REQUEST_COOLDOWN = 2000; // 2 seconds cooldown between requests

// Global cache
const subscriptionCache = {
  data: null,
  timestamp: null,
  TTL: 5 * 60 * 1000, // 5 minutes
};

export const useSubscription = () => {
  const { state, dispatch } = useContext(AuthContext);
  const jtoken = getCookie("taprr-token");
  const fetchInProgress = useRef(false);
  const abortControllerRef = useRef(null);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
      if (abortControllerRef.current) {
        try {
          abortControllerRef.current.abort();
        } catch (e) {}
        abortControllerRef.current = null;
      }
      fetchInProgress.current = false;
    };
  }, []);

  const isSubscribed = useCallback(() => {
    return (
      state.userProfile.subscription?.status === "active" ||
      state.userProfile.subscription?.status === "trialing"
    );
  }, [state.userProfile.subscription?.status]);

  const requiresSubscription = useCallback(
    (callback) => {
      return (...args) => {
        if (!isSubscribed()) {
          return false;
        }
        return callback(...args);
      };
    },
    [isSubscribed]
  );

  const fetchSubscription = useCallback(async () => {
    // Don't proceed if conditions aren't met
    if (!jtoken || !isMounted.current || fetchInProgress.current) return;

    // Check request cooldown
    const now = Date.now();
    if (now - lastRequestTime < REQUEST_COOLDOWN) {
      return;
    }

    // If there's an active request, wait for it
    if (activeRequest) {
      try {
        const result = await activeRequest;
        if (isMounted.current && result?.success) {
          dispatch?.updateUserSubscription(result.subscription);
        }
        return;
      } catch (error) {
        console.error("Error waiting for active request:", error);
      }
    }

    let localAbortController = null;
    let requestPromise = null;
    fetchInProgress.current = true;

    try {
      // Check cache first
      if (
        subscriptionCache.data &&
        subscriptionCache.timestamp &&
        now - subscriptionCache.timestamp < subscriptionCache.TTL
      ) {
        if (dispatch?.updateUserSubscription && isMounted.current) {
          dispatch.updateUserSubscription(subscriptionCache.data);
          return;
        }
      }

      // Create new abort controller
      localAbortController = new AbortController();
      abortControllerRef.current = localAbortController;

      // Create the request promise
      requestPromise = getUserSubscription({
        jtoken,
        signal: localAbortController.signal,
      }).catch((error) => {
        if (error.name === "AbortError") {
          return { success: false, aborted: true };
        }
        throw error;
      });

      // Set as active request
      activeRequest = requestPromise;
      lastRequestTime = now;

      const response = await requestPromise;

      // Don't proceed if aborted or unmounted
      if (!isMounted.current || response.aborted) return;

      if (response.success && dispatch?.updateUserSubscription) {
        subscriptionCache.data = response.subscription;
        subscriptionCache.timestamp = now;
        dispatch.updateUserSubscription(response.subscription);
      }

      return response;
    } catch (error) {
      if (isMounted.current && error.name !== "AbortError") {
        console.error("Error fetching subscription:", error);
      }
    } finally {
      if (isMounted.current) {
        fetchInProgress.current = false;
        if (abortControllerRef.current === localAbortController) {
          abortControllerRef.current = null;
        }
      }
      // Clear active request if it's ours
      if (activeRequest === requestPromise) {
        activeRequest = null;
      }
    }
  }, [jtoken, dispatch?.updateUserSubscription]);

  const forceRefresh = useCallback(() => {
    if (!isMounted.current) return;

    // Reset cache and state
    subscriptionCache.data = null;
    subscriptionCache.timestamp = null;
    fetchInProgress.current = false;
    activeRequest = null;
    lastRequestTime = 0;

    if (abortControllerRef.current) {
      try {
        abortControllerRef.current.abort();
      } catch (e) {}
      abortControllerRef.current = null;
    }

    return fetchSubscription();
  }, [fetchSubscription]);

  return {
    isSubscribed,
    requiresSubscription,
    fetchSubscription,
    forceRefresh,
  };
};
